import React, { useContext } from 'react'
import { PrimaryButton, SecondaryButton } from '@hm-group/fabric-components'
import { getkeyvalueText } from '../../../util/helperfunctions'
import { UserContext } from '../../../context/userContext'

const ModalButtons = ({ type, handle__CTA_action, commonRedirect, isshowkey }) => {
  const {lslKeyValue} = useContext(UserContext)
  const lslKeyData = lslKeyValue.lslKeyData

  const renderPrimaryButton = (textKey, onClickHandler) => (
    <PrimaryButton
      className="submit__btn"
      onClick={onClickHandler}
    >
      {getkeyvalueText(textKey.category, textKey.label, isshowkey, lslKeyData)}
    </PrimaryButton>
  )

  const renderSecondaryButton = (textKey, onClickHandler) => (
    <SecondaryButton
      className="cancel__btn"
      onClick={onClickHandler}
    >
      {getkeyvalueText(textKey.category, textKey.label, isshowkey, lslKeyData)}
    </SecondaryButton>
  )

  const buttonConfigs = {
    'welcome_user': {
      primary: { textKey: { category: 'Welcomepop', label: 'gotItbtnLabel' }, onClick: commonRedirect },
      secondary: null
    },
    'customerService': {
      primary: { textKey: { category: 'Welcomepop', label: 'gotItbtnLabel' }, onClick: commonRedirect },
      secondary: null
    },
    'notify_logOff': {
      primary: { textKey: { category: 'LandingPage', label: 'logoutbtnLabel' }, onClick: handle__CTA_action },
      secondary: { textKey: { category: 'Lightboxes', label: 'stayheretbtnLabel' }, onClick: commonRedirect }
    },
    'session_timeout_warning': {
      primary: { textKey: { category: 'Lightboxes', label: 'stayheretbtnLabel' }, onClick: commonRedirect },
      secondary: { textKey: { category: 'LandingPage', label: 'logoutbtnLabel' }, onClick: handle__CTA_action }
    }
  }

  const { primary, secondary } = buttonConfigs[type] || {}

  return (
    <div className="cta__lightbox_buttons">
      {primary && renderPrimaryButton(primary.textKey, primary.onClick)}
      {secondary && renderSecondaryButton(secondary.textKey, secondary.onClick)}
    </div>
  )
}

export default ModalButtons
