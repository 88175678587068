import React from 'react'
import './LightBox.scss'
import { getBrandImagebyName } from '../../util/helperfunctions'
import NotifyAccessText from './components/NotifyAccessText'
import NotifyDeleteProcess from './components/NotifyDeletion'
import NotifySessionTimeout from './components/NotifySessionTimeout'
import LogOff from './components/LogOff'
import {
  CustomerServiceHeader,
  NotifyAccessHeader,
  WelcomeTextHeader,
  LogOffText,
  SessionTimeoutText,
  NotifyDeleteText,
} from './components/CustomHeaderText'
import { WelcomeUser, Brand_Logo_Section } from './components/WelcomeUser'
import NotifyCustomerService from './components/NotifyCustomerService'
import ModalButtons from './components/ModalButtons'
import ModalComponent from '../Modal/ModalComponent'

const LightBox = ({ open, handleClose, brand, type, handle__CTA_action, isGuestInfoClicked, commonRedirect }) => {

  const isshowkey = false // temporary

  const onClose = (value) => {
    handleClose(value)
  }

  const renderHeaderText = () => {
    switch (type) {
      case 'accessPersonalData':
        return <NotifyAccessHeader isshowkey={isshowkey} />
      case 'customerService':
        return <CustomerServiceHeader isshowkey={isshowkey} />
      case 'welcome_user':
        return <WelcomeTextHeader isshowkey={isshowkey} />
      case 'notify_logOff':
        return <LogOffText isshowkey={isshowkey} />
      case 'session_timeout_warning':
        return <SessionTimeoutText isshowkey={isshowkey} />
      case 'notifydelete':
        return <NotifyDeleteText isshowkey={isshowkey} />
      default:
        return null
    }
  }

  const renderBodyContent = () => {
    switch (type) {
      case 'accessPersonalData':
        return <NotifyAccessText isGuestInfoClicked={isGuestInfoClicked} />
      case 'notifydelete':
        return <NotifyDeleteProcess isGuestInfoClicked={isGuestInfoClicked}/>
      case 'welcome_user':
        return (
          <div>
            <WelcomeUser isshowkey={isshowkey} />
            <Brand_Logo_Section />
          </div>
        )
      case 'notify_logOff':
        return <LogOff isshowkey={isshowkey} />
      case 'customerService':
        return (
          <NotifyCustomerService
            isshowkey={isshowkey}
          />
        )
      case 'session_timeout_warning':
        return <NotifySessionTimeout isshowkey={isshowkey} />
      default:
        return null
    }
  }

  const RenderContent = () => {
    return (
      <div>
     
          <div className="content">
            <div className="brand__img">
              <img
                className="brand_puff_logo_lightbox"
                src={getBrandImagebyName(type === 'welcome_user' || type === 'notify_logOff' || type === 'session_timeout_warning' ? 'HMGroup' : brand)}
                alt="H&M Group"
              />
            </div>
            <div className="modalHeader" data-testid="customer_service_text">
              {renderHeaderText()}
            </div>
            <div className="modalBody">
              {renderBodyContent()}
            </div>
          </div>
  
          <div>
            <ModalButtons
              handle__CTA_action={handle__CTA_action}
              commonRedirect={commonRedirect}
              type={type}
              isshowkey={isshowkey}
            />
          </div>
  
      </div>
    )
  }
  

  return (
   <ModalComponent openModal = {open} children={<RenderContent/>} onClose={onClose}/>
  )
}

export default LightBox
