export const APIHOST = 'https://amd-privacy.hmgroup.com'
export const AUTHAPIHOST = 'http://privacyportal-aut:8080/'

export const Country = [
  { key: '0', value: 'HM' },
  { key: '1', value: 'COS' },
  { key: '2', value: 'MONKI' },
  { key: '3', value: 'WEEKDAY' },
  { key: '4', value: 'CHEAPMONDAY' },
  { key: '5', value: 'STORIES' },
  { key: '6', value: 'ARKET' },
]

export const DisplayCountry = [
  { key: '0', value: 'H&M' },
  { key: '1', value: 'COS' },
  { key: '2', value: 'Monki' },
  { key: '3', value: 'Weekday' },
  { key: '4', value: 'CHEAPMONDAY' },
  { key: '5', value: '& Other Stories' },
  { key: '6', value: 'Arket' },
]

export const futureCommerceMarket = [{ key: '', value: [''] }]

export const centraMarket = [
  {
    key: '3',
    value: [
      'DK',
      'BG',
      'HR',
      'CY',
      'CZ',
      'EE',
      'FI',
      'GR',
      'HU',
      'LV',
      'LT',
      'LU',
      'PL',
      'RO',
      'SK',
      'SI',
      'ES',
      'IT',
      'FR',
      'PT',
      'DE',
      'AT',
      'BE',
      'LU',
      'UK',
      'NO',
      'CH',
      'AD',
      'AR',
      'AU',
      'BH',
      'BD',
      'BB',
      'BN',
      'CA',
      'CL',
      'CR',
      'EC',
      'SV',
      'FO',
      'GI',
      'GL',
      'GU',
      'GT',
      'GG',
      'HN',
      'HK',
      'IS',
      'IN',
      'ID',
      'IL',
      'JM',
      'JP',
      'JE',
      'KE',
      'LI',
      'MO',
      'MY',
      'MV',
      'MX',
      'MN',
      'MA',
      'NC',
      'NZ',
      'NG',
      'MP',
      'PE',
      'PH',
      'KN',
      'WS',
      'SM',
      'RS',
      'SG',
      'ZA',
      'KR',
      'SR',
      'TW',
      'TH',
      'TR',
      'AE',
      'UY',
      'VN',
    ],
  },
  { key: '1', value: ['AU', 'BG','HR', 'CY', 'CZ', 'EE', 'FI', 'DE', 'GR', 'HU', 'LV', 'LT', 'PT', 'RO', 'SK', 'SI'] },
  { key: '5', value: ['DK', 'NL'] },
  { key: '6', value: ['DK', 'NL'] },
]

//Either use Comma Seperated Values or use 'allMarkets' Identifier, If left empty Improved CIC will not work
export const improvedCICMarkets = [
  { key: '0', value: ['allMarkets'] },
  { key: '1', value: ['allMarkets'] },
  { key: '2', value: ['allMarkets'] },
  { key: '3', value: ['allMarkets'] },
  { key: '4', value: ['allMarkets'] },
  { key: '5', value: ['allMarkets'] },
  { key: '6', value: ['allMarkets'] },
]

export const brands = [
    {brandId:'0',brandName: 'HM', brandDisplayName:'H&M', brandSite: 'hm.com', ccpaLink: "https://www2.hm.com/en_us/customer-service/legal-and-privacy/do-not-sell-ca-form.html"},
    {brandId:'1',brandName: 'COS', brandDisplayName:'COS', brandSite: 'cos.com', ccpaLink: "https://www.cos.com/content/cos/page.countryselector.en_US.en_usd.USD.jsp?goeorguri=/en_usd/customer-service/privacy-notice/do-not-sell-my-personal-data.html"},
    {brandId:'2',brandName: 'MONKI', brandDisplayName:'Monki', brandSite: 'monki.com', ccpaLink: "https://www2.hm.com/en_us/customer-service/legal-and-privacy/do-not-sell-ca-form.html"},
    {brandId:'3',brandName: 'WEEKDAY', brandDisplayName:'Weekday', brandSite: 'weekday.com', ccpaLink: "https://www2.hm.com/en_us/customer-service/legal-and-privacy/do-not-sell-ca-form.html"},
    {brandId:'4',brandName: 'CHEAPMONDAY', brandDisplayName:'CHEAPMONDAY', brandSite: 'weekday.com', ccpaLink: "https://www2.hm.com/en_us/customer-service/legal-and-privacy/do-not-sell-ca-form.html"},
    {brandId:'5',brandName: 'STORIES', brandDisplayName:'& Other Stories', brandSite: 'stories.com', ccpaLink: "https://www.stories.com/content/stories/page.countryselector.en_US.en_usd.USD.jsp?goeorguri=/en_usd/customer-service/do-not-sell-my-personal-data.html"},
    {brandId:'6',brandName: 'ARKET', brandDisplayName:'Arket', brandSite: 'arket.com', ccpaLink: "https://www2.hm.com/en_us/customer-service/legal-and-privacy/do-not-sell-ca-form.html"}
]
