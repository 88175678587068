import React, { useEffect, useState, Suspense, useContext } from 'react'
import './DataTab.scss'
import { transformData } from '../../Services/DataService'
import DataBox from './components/DataBox'
import { BodyText, HeadingLarge, ListGrid, GhostLink, BannerNotification } from '@hm-group/fabric-components'
import { useLocation } from 'react-router-dom'
import {
  getBrandImagebyID,
  getkeyvalueText,
  identifyEvent,
  getSourceBpid,
  getSortedArray,
} from '../../util/helperfunctions'
import { getAllBPidForEmailogin } from '../../Services/DataService'
import MyLoader from '../../util/ContentLoader'
import { AnalyticsContext } from '../../context/analyticsContext'
import SessionStorageService from '../../Services/SessionStorageService'
import LightBox from '../LightBoxes/LightBox'
import { UserContext } from '../../context/userContext'
import { EVENTACTION, EVENTOUTCOME, EVENTTYPE, PAGE } from '../../util/constants'

const PersonalDataTab = ({ handleToggleAccordian }) => {
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)

  let data = []

  const {lslKeyValue,updateLslKeyValue} = useContext(UserContext);
  const { emailid = localStorage.getItem('userName') } = (lslKeyValue && lslKeyValue.plainres) || {};
  const { isNewUser = '' } = (lslKeyValue) || {};


  const [listData, setlistData] = useState([])

  const isshowkey = false // temporary

  const [showLoader, setshowLoader] = useState(true)
  const [isError, setIsError] = useState(false)
  const [pageRefresh, setpageRefresh] = useState(false)

  const { isFeedbackSubmitted } = location.state || { isFeedbackSubmitted: false }
  const { analyticsData,updateAnalyticsData, setAnalyticsData } = useContext(AnalyticsContext)

  useEffect(() => {
    if (isNewUser) {
      setIsOpen(true);
    } 
  },[isNewUser])

  const handleClose = () => {
    setIsOpen(false)
    updateLslKeyValue({isNewUser: false})
    updateAnalyticsData({
      ...analyticsData,
      eventType: EVENTTYPE.overview,
      page: PAGE.overviewPage,
      eventAction : EVENTACTION.landOnOverviewPage,
      eventOutcome : EVENTOUTCOME.success,
    })
  }



  useEffect(() => {
    const fetchBPIDData = async () => {
      setIsError(false)
      setshowLoader(true)
      handleToggleAccordian('reset')
      const tempData = {
        userName: emailid,
        entryType: '002',
      }

      try {
        const apiResponse = await getAllBPidForEmailogin(tempData)
        let sourceBPID = SessionStorageService.getItem('sourceBPID') || null
        let fallbackBPID = false

        if (apiResponse.status === 200 && apiResponse.data.customers.length > 0) {
          if (!sourceBPID && analyticsData.sourceCountry && analyticsData.sourceBrand) {
            sourceBPID = getSourceBpid(apiResponse, {
              countrycode: analyticsData.sourceCountry,
              brandid: analyticsData.sourceBrand,
            })

            if (!sourceBPID) {
              fallbackBPID = true
              // Optionally add some logs here for debugging
            }
          }

          if (sourceBPID || fallbackBPID) {
            setAnalyticsData((prev) => ({ ...prev, sourceBPID }))
            SessionStorageService.setItem('sourceBPID', sourceBPID)

            const sortedData = getSortedArray(
              apiResponse.data.customers,
              { businessPartnerId: sourceBPID, corporateBrandId: '5' },
              'corporateBrandId',
            )

            const data = transformData(sortedData, sourceBPID)

            if (data.length > 0) {
              setlistData(data)
            }

            setshowLoader(false)
          }
        }
        else {
          setIsError(true)
        }
      } catch (e) {
        console.error(e)
        setIsError(true)
      } finally {
        if (data.length > 0) {
          setshowLoader(false)
        }
      }
    }

    fetchBPIDData()
  }, [pageRefresh,analyticsData.sourceCountry,analyticsData.sourceBrand])

  return (
    <ListGrid
      numberOfColumns={{
        xsmall: 1,
        small: 1,
        medium: 1,
        xlarge: 1,
      }}
      tabIndex={-1}
    >
      <div className="bpDataSection">
        {isFeedbackSubmitted ? (
          <BannerNotification
            closeLabel="Close"
            message={getkeyvalueText('Lightboxes', 'thanksfeedbackHeaderLabel', isshowkey, lslKeyValue.lslKeyData)}
            severity="success"
          />
        ) : (
          ''
        )}
        <HeadingLarge Tag="h1">
          {getkeyvalueText('login', 'mpd_heading', isshowkey, lslKeyValue.lslKeyData)}
        </HeadingLarge>
        <BodyText Tag="p" className="content_holder _extramargintop30" data-testid="content_holder">
          {getkeyvalueText('LandingPage', 'engagementsLabel', isshowkey, lslKeyValue.lslKeyData)}
        </BodyText>
        <br />
        <BodyText Tag="p" className="content_holder" data-testid="content_holder">
          {getkeyvalueText('LandingPage', 'custdatainfoLabel', isshowkey, lslKeyValue.lslKeyData)}
        </BodyText>

        {isError ? (
          <div className="error_section" data-testid="error_section">
            <BodyText Tag="p">
              {getkeyvalueText('forgotpwd', 'tryagnLabel', isshowkey, lslKeyValue.lslKeyData)}
            </BodyText>
            <GhostLink
              onClick={() => setpageRefresh(!pageRefresh)}
              onKeyDown={(event) => {
                identifyEvent(event) && setpageRefresh(!pageRefresh)
              }}
              tabIndex={0}
              data-testid="page_refresh_link"
            >
              {getkeyvalueText('LandingPage', 'retryloadingLabel', isshowkey, lslKeyValue.lslKeyData)}
            </GhostLink>
          </div>
        ) : (
          ''
        )}

        <Suspense fallback={<h1>Loading data...</h1>}>
          <div className="brands__puff_holder">
            {showLoader ? (
              <div className="loader_section">
                <MyLoader width={600} height={250} />
              </div>
            ) : (
              ''
            )}

            {listData &&
              listData.map((e) => (
                <div key={e.brandId} className="brand__box">
                  <div className="div_brand_puff_logo">
                    <img
                      className="brand_puff_logo"
                      src={getBrandImagebyID(e.brandId)}
                      alt={getBrandImagebyID(e.brandId)}
                    ></img>
                  </div>
                  {e.items.map((ele) => {
                    if (ele.corporateBrandId == e.brandId) {
                      return <DataBox data={ele} />
                    }
                  })}
                </div>
              ))}
          </div>
        </Suspense>
        <LightBox open={isOpen} handleClose={handleClose} commonRedirect={handleClose} type={'welcome_user'} />
      </div>
    </ListGrid>
  )
}

export default PersonalDataTab
