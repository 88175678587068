
import React, { useContext } from 'react';
import { getkeyvalueText } from '../../../util/helperfunctions'
import * as qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { HeadingLarge } from '@hm-group/fabric-components'
import { UserContext } from '../../../context/userContext';

export const NotifyAccessHeader = (isshowkey) => {
    const {lslKeyValue} = useContext(UserContext);
    return <HeadingLarge Tag="h1">{getkeyvalueText('ManageServices', 'processrequestaccessLabel', isshowkey, {lslKeyValue}.lslKeyData)}</HeadingLarge>;
}
export const CustomerServiceHeader = (isshowkey) => {
    const {lslKeyValue} = useContext(UserContext);
    return <HeadingLarge Tag="h1">{getkeyvalueText('Lightboxes', 'contactCSheaderLabel', isshowkey, {lslKeyValue}.lslKeyData)}</HeadingLarge>;
}
export const StartDeleteHeader = (isshowkey) => {
    const {lslKeyValue} = useContext(UserContext);
    return <HeadingLarge Tag="h1">{getkeyvalueText('Lightboxes', 'areusureLabel', isshowkey, {lslKeyValue}.lslKeyData)}</HeadingLarge>;
}
export const WelcomeTextHeader = (isshowkey) => {
    const {lslKeyValue} = useContext(UserContext);
    return <div><HeadingLarge Tag="h1">{getkeyvalueText('login', 'mpd_heading', isshowkey, {lslKeyValue}.lslKeyData)}</HeadingLarge></div>
}
export const LogOffText = (isshowkey) => {
    const {lslKeyValue} = useContext(UserContext);
    return <HeadingLarge Tag="h1">{getkeyvalueText('Lightboxes', 'logoffHeaderLabel', isshowkey, {lslKeyValue}.lslKeyData)}</HeadingLarge>;
}
export const SessionTimeoutText = () => {
    const {lslKeyValue} = useContext(UserContext);
    return <HeadingLarge Tag="h1">{getkeyvalueText('Lightboxes', 'sessiontimeoutHeaderLabel', (qs.parse(useLocation().search).showkeys), {lslKeyValue}.lslKeyData)}</HeadingLarge>
}

export const NotifyDeleteText = (isshowkey) => {
    const {lslKeyValue} = useContext(UserContext);
    return <HeadingLarge Tag="h1">{getkeyvalueText('ManageServices', 'processrequestaccessLabel', isshowkey, {lslKeyValue}.lslKeyData)}</HeadingLarge>;
}
