import React from 'react'
import { getCountryConsentAgebyCode, getEmailConsentText, getkeyvalueText } from '../../../util/helperfunctions'
import { brands } from '../../../util/util'
import { BodyText ,RadioButtonInput ,FormLabel} from '@hm-group/fabric-components'

export const EmailMarketing = ({ userIsSubscribedToFashionNews, handleRadioInput, lslKeyValue,corporateBrandId,countryCode }) => {
  const isshowkey = false
  const selectedBrand = brands.find((e) => e.brandId === corporateBrandId)
  return (
    <>
      <BodyText Tag="p">{getkeyvalueText('commonKey', 'emailsubsLabel', isshowkey, lslKeyValue.lslKeyData)}</BodyText>
      <div className="radio-group">
        <RadioButtonInput
          className="radio-input"
          id="emailmarketing-opt-in"
          name="hmEmailMarketingSubscription"
          labels={{
            errorLabel: '',
            label: `${getkeyvalueText('ManageServices', 'yesLabel', isshowkey, lslKeyValue.lslKeyData)}`,
          }}
          onChange={handleRadioInput}
          isChecked={userIsSubscribedToFashionNews}
        />
        <FormLabel
          className={`info-text-subscription subscription-disclaimer ${userIsSubscribedToFashionNews ? 'visible' : ''}`}
          Tag="label"
          htmlFor="emailmarketing-opt-in"
        >
          {getEmailConsentText(
            selectedBrand,
            getCountryConsentAgebyCode(countryCode, selectedBrand),
            isshowkey,
            lslKeyValue.lslKeyData,
          )}
        </FormLabel>
        <RadioButtonInput
          className="radio-input"
          id="emailmarketing-opt-out"
          name="hmEmailMarketingSubscription"
          labels={{
            errorLabel: '',
            label: `${getkeyvalueText('ManageServices', 'noLabel', isshowkey, lslKeyValue.lslKeyData)}`,
          }}
          onChange={handleRadioInput}
          isChecked={!userIsSubscribedToFashionNews}
        />
      </div>
    </>
  )
}
