
import { brandsLogo } from "../assets/images";
import { brands, Country, DisplayCountry } from "./util";
import { CountryCode } from './CountrySelector';
import { PrivacyUrlViaLocaleSelector } from './PrivacyNoticeURL';
import { futureCommerceMarket, centraMarket} from './util';
import {
    InlineLink,
    MetaText,
  } from '@hm-group/fabric-components'

import { brandNameFromID, commonConstants, countryNameFromID } from "./constants";


export const getBrandImagebyID = (id) => {
    let img = '';
    brandsLogo.forEach(item => {
        if (id == item.brandId) {
            item.brandId == id && (img = item.logo);
        }
    })
    return img
}

export const getBrandImagebyName = (name) => {
    let img = '';
    brandsLogo.forEach(item => {
        if (name == item.brandName) {
            item.brandName == name && (img = item.logo);
        }
    })
    return img
}

export const getBrandIdbyName = (brandName) => {
    let brandid = "";
    Country.forEach(e => {
        if (e.value == brandName) {
            brandid = e.key;
            // break;
        }
    })
    return brandid;
}



export const getCountryNamebyCode = (countryCode, showkey, data, pageName = "Country") => {
    let countryName = "";
    let finalreturnvalue = ""
    CountryCode.forEach(e => {
        if (e.countryCode == countryCode) {
            countryName = e.countryName
        }
    })
    if (data) {
        finalreturnvalue = countryName;
    } else if ( data) {

        finalreturnvalue = getkeyvalueText(pageName, countryName, showkey, data);
    } else {
        finalreturnvalue = countryName;
    }
    return finalreturnvalue;
}


export const getCountryConsentAgebyCode = (selectedCountryCode, selectedBrand) => {
    let consentAge = "";
    const selectedBrandId = selectedBrand && selectedBrand.brandId
    const countryCodes = CountryCode && CountryCode.find(e => e.countryCode === selectedCountryCode)
    countryCodes && countryCodes.ageByBrands.forEach(e => {
        if (e.brandId == selectedBrandId) {
            consentAge = e.brandAge
        }
    })
    return consentAge;
}


export const getDisplayBrandNamebyID = (brandId) => {
    let brandName = "";
    DisplayCountry.forEach(e => {
        if (e.key == brandId) {
            brandName = e.value;
            // break;
        }
    })
    return brandName;
}




export const getkeyvalueText = (pageName, keyName, showkey, data) => {
    return (data && data[pageName] && data[pageName][keyName]) || "";
};

export const getBrandSite = (brandId) => {
    let brandSite = "";
    brands.forEach(e => {
        if (e.brandId == brandId) {
            brandSite = e.brandSite;
        }
    })
    return brandSite;

}

// To retrieve attributes from URL Params
export const extractAttributeValueFromParams = (str, attribute) => {
    var regex = new RegExp(attribute + "=([^&]+)");
    var match = regex.exec(str);
    if (match) {
      var value = match[1];
      return value;
    }
    return null;
  }

// adding selected brand object as first param
export const getEmailConsentText = (selectedBrand, age, isshowkey, data) => {
    const brandDisplayName = selectedBrand && selectedBrand.brandDisplayName
    return getkeyvalueText('EmailConsentTexts', 'nothmonlyconsentLabel', isshowkey, data).replace('{year}', age).replace('{Brand}', brandDisplayName);
}

export const  preprocessData = (data) => {
    const lookup = {};

    data.forEach(item => {
        const { page, key, translation } = item;
        if (!lookup[page]) {
            lookup[page] = {};
        }
        lookup[page][key] = translation;
    });

    return lookup;
};


export const retrieveCcpaLink = (brand, country, lslKeyValue) => {
    let ccpaLink = "";
    ccpaLink = brands.find(e => e.brandId === brand);
    if (ccpaLink != "" ) {
        ccpaLink = ccpaLink.ccpaLink;
    }
    return ccpaLink;
}


export const isFutureCommerceMarket = (brand,country) => {

    let listcountry = '';
    futureCommerceMarket.forEach(e => {
        if (e.key == getBrandIdbyName(brand)) {
            listcountry = e.value
        }
    })    
    if (listcountry.indexOf(country) !== -1) {
        return true
    } else {
        return false
    }
}

export const isCentraMarket = (brand,country) => {

    let listcountry = '';
    centraMarket.forEach(e => {
        if (e.key == getBrandIdbyName(brand)) {
            listcountry = e.value
        }
    })    
    if (listcountry.indexOf(country) !== -1) {
        return true
    } else {
        return false
    }
    
}


export const GetCSEmailHtml = ({brand, country, lslKeyData, isshowkey, align}) => {
    let email = "";
    if (brand != undefined && country != undefined) {
        if (brand == "HM") {
            let link = `dataprotection.${country && country.toLowerCase()}@${brand.toLowerCase()}.com`;
            email = <div className={align == "left" ? "cshtmllinks" : "cshtmlcentrealign"}>
                    <InlineLink className='anchorfont' href={"mailto:"+link}>{link}</InlineLink>
                    </div>
        } else if (brand == "ARKET" && country == "BE") {
            let link1 = `klantenservice.be@arket.com`
            let link2= `serviceclients.be@arket.com`
            email = <div className={align == "left" ? "cshtmllinks" : "cshtmlcentrealign"}>
                        <InlineLink className='anchorfont' href={"mailto:"+link1}>{link1}</InlineLink>
                        <MetaText className="cshtmlmeta" alignment={align == "left"? "left" : "center"} isMarginless Tag="p">({getkeyvalueText('commonKey', 'dutchspkLabel', isshowkey, lslKeyData)}</MetaText>
                        <InlineLink className='anchorfont' href={"mailto:"+link2}>{link2}</InlineLink>
                        <MetaText className="cshtmlmeta" alignment={align == "left"? "left" : "center"} isMarginless Tag="p">{getkeyvalueText('commonKey', 'FrenchspkLabel', isshowkey, lslKeyData)}</MetaText>
                    </div>
        } else if (brand == "ARKET" && country == "LU") {
            let link1 = `kundenservice.lu@arket.com`
            let link2= `serviceclient.lu@arket.com`
            email = <div className={align == "left" ? "cshtmllinks" : "cshtmlcentrealign"}>
                        <InlineLink className='anchorfont' href={"mailto:"+link1}>{link1}</InlineLink>
                        <MetaText className="cshtmlmeta" alignment={align == "left"? "left" : "center"} isMarginless Tag="p">{getkeyvalueText('commonKey', 'GermanspkLabel', isshowkey, lslKeyData)}</MetaText>
                        <InlineLink className='anchorfont' href={"mailto:"+link2}>{link2}</InlineLink>
                        <MetaText className="cshtmlmeta" alignment={align == "left"? "left" : "center"} isMarginless Tag="p">{getkeyvalueText('commonKey', 'FrenchspkLabel', isshowkey, lslKeyData)}</MetaText>
                    </div>
        } else if (brand == "WEEKDAY" && country == "CH") {
            let link1 = `kundenservice.ch@weekday.com`
            let link2= `customerservice@weekday.com`
            email = <div className={align == "left" ? "cshtmllinks" : "cshtmlcentrealign"}>
                        <InlineLink className='anchorfont' href={"mailto:"+link1}>{link1}</InlineLink>
                        <MetaText className="cshtmlmeta" alignment={align == "left"? "left" : "center"} Tag="p">{getkeyvalueText('commonKey', 'GermanspkLabel', isshowkey, lslKeyData)}</MetaText>
                        <InlineLink className='anchorfont' href={"mailto:"+link2}>{link2}</InlineLink>
                        <MetaText className="cshtmlmeta" alignment={align == "left"? "left" : "center"} Tag="p">{getkeyvalueText('commonKey', 'EnglishspkLabel', isshowkey, lslKeyData)}</MetaText>
                    </div>
        } else if (brand == "STORIES" && country == "BE") {
            let link1 = `klantenservice.be@stories.com`
            let link2= `serviceclient.be@stories.com`
            email = <div className={align == "left" ? "cshtmllinks" : "cshtmlcentrealign"}>
                        <InlineLink className='anchorfont' href={"mailto:"+link1}>{link1}</InlineLink>
                        <MetaText className="cshtmlmeta" alignment={align == "left"? "left" : "center"} isMarginless Tag="p">{getkeyvalueText('commonKey', 'dutchspkLabel', isshowkey, lslKeyData)}</MetaText>
                        <InlineLink className='anchorfont' href={"mailto:"+link2}>{link2}</InlineLink>
                        <MetaText className="cshtmlmeta" alignment={align == "left"? "left" : "center"} isMarginless Tag="p">{getkeyvalueText('commonKey', 'FrenchspkLabel', isshowkey, lslKeyData)}</MetaText>
                    </div>
        } else if (brand == "STORIES" && country == "LU") {
            let link1 = `kundenservice.lu@stories.com`
            let link2= `serviceclient.lu@stories.com`
            email = <div className={align == "left" ? "cshtmllinks" : "cshtmlcentrealign"}>
                        <InlineLink className='anchorfont' href={"mailto:"+link1}>{link1}</InlineLink>
                        <MetaText className="cshtmlmeta" alignment={align == "left"? "left" : "center"} isMarginless Tag="p">{getkeyvalueText('commonKey', 'GermanspkLabel', isshowkey, lslKeyData)}</MetaText>
                        <InlineLink className='anchorfont' href={"mailto:"+link2}>{link2}</InlineLink>
                        <MetaText className="cshtmlmeta" alignment={align == "left"? "left" : "center"} isMarginless Tag="p">{getkeyvalueText('commonKey', 'FrenchspkLabel', isshowkey, lslKeyData)}</MetaText>
                    </div>
        } else if (brand == "STORIES" && country == "CH") {
            let link1 = `kundenservice.ch@stories.com`
            let link2= `serviceclient.ch@stories.com`
            let link3= `servizioclienti.ch@stories.com`
            email = <div className={align == "left" ? "cshtmllinks" : "cshtmlcentrealign"}>
                        <InlineLink className='anchorfont' href={"mailto:"+link1}>{link1}</InlineLink>
                        <MetaText className="cshtmlmeta" alignment={align == "left"? "left" : "center"} isMarginless Tag="p">{getkeyvalueText('commonKey', 'GermanspkLabel', isshowkey, lslKeyData)}</MetaText>
                        <InlineLink className='anchorfont' href={"mailto:"+link2}>{link2}</InlineLink>
                        <MetaText className="cshtmlmeta" alignment={align == "left"? "left" : "center"} isMarginless Tag="p">{getkeyvalueText('commonKey', 'FrenchspkLabel', isshowkey, lslKeyData)}</MetaText>
                        <InlineLink className='anchorfont' href={"mailto:"+link3}>{link3}</InlineLink>
                        <MetaText className="cshtmlmeta"  alignment={align == "left"? "left" : "center"} isMarginless Tag="p">{getkeyvalueText('commonKey', 'ItalianspkLabel', isshowkey, lslKeyData)}</MetaText>
                    </div>
        } else if (brand == "COS") {
            let link = `customerservice@cosstores.com`
            email = <div className={align == "left" ? "cshtmllinks" : "cshtmlcentrealign"}>
                    <InlineLink className='anchorfont' href={"mailto:"+link}>{link}</InlineLink>
                    </div>
        } 
        else {
            let link = `customerservice@${brand.toLowerCase()}.com`
            email = <div className={align == "left" ? "cshtmllinks" : "cshtmlcentrealign"}>
                <InlineLink className='anchorfont' href={"mailto:"+link}>{link}</InlineLink>
                </div>
        }
    }
    return email;
}

export const retrievePrivacyLink = (brand, country, lslKeyValue) => {
    let privacyUrl = "";
    let currentCountry = country;
    let availableBrandLanguages;
    if (brand != undefined && currentCountry != undefined) {
        PrivacyUrlViaLocaleSelector.forEach(e => {
            if ((e.CountryWiseURL).findIndex(obj => obj.countryCode === currentCountry) != -1) {
                e.brandID == brand && e.CountryWiseURL.filter(el =>
                    el.countryCode == currentCountry).forEach(item => {
                        // Check for language mapping first
                        if (item.languageMapping &&
                            lslKeyValue?.preferredLanguage &&
                            item.languageMapping[lslKeyValue.preferredLanguage.toLowerCase()]) {
                            privacyUrl = item.languageMapping[lslKeyValue.preferredLanguage.toLowerCase()].url;
                        } else {
                            privacyUrl = item.privacyUrl;
                        }
                        if (item.hasOwnProperty("brandLanguages")) {
                            availableBrandLanguages = item.brandLanguages;
                        }
                    })
            } else {
                e.brandID == brand && e.CountryWiseURL.filter(el =>
                    el.countryCode != currentCountry && el.countryCode == "defaultGlobal").forEach(item => {
                        privacyUrl = item.privacyUrl
                    })
                
            }
        })
        //New
        //Enhancement for Multiple Languages in HM & PB Brands
        if ((privacyUrl != "") && ((privacyUrl.indexOf('lang=') != -1) || (privacyUrl.indexOf('selector.') != -1)) && availableBrandLanguages != undefined) {
            let searchkey;
            if (lslKeyValue && lslKeyValue.preferredLanguage != "" && availableBrandLanguages.indexOf((lslKeyValue.preferredLanguage.toLowerCase())) != -1) {
                // Skip URL modification if we already have a mapped URL
                if (!PrivacyUrlViaLocaleSelector[0].CountryWiseURL.find(
                    item => item.countryCode === currentCountry)?.languageMapping?.[lslKeyValue.preferredLanguage.toLowerCase()]) {
                    if (privacyUrl.indexOf('lang=') != -1) {
                        searchkey = 'lang=';
                    } else {
                        searchkey = 'selector.';
                    }
                    privacyUrl = privacyUrl.split(searchkey)[0] + searchkey + ((lslKeyValue.preferredLanguage).toLowerCase()) + privacyUrl.split(searchkey)[1].slice(2);
                }
            }
        }
    }
    return privacyUrl;
}

/**
 * it will be used to get source bpid 
 * @param {*} apiResponse 
 * @param {*} parsedData 
 * @returns source bpid
 */
export const getSourceBpid = (apiResponse, parsedData) => {
    const filtered = apiResponse.data.customers.filter(
      (each) => each.countryCode === parsedData.countrycode && each.corporateBrandId === parsedData.brandid
    );
    return filtered.length ? filtered[0]?.businessPartnerId : '';
  };
/**
 * will be used to fetch operating system
 * @returns OS
 */
const getOS = ()=>{
    if (navigator.userAgent.match(/Android/i)) {
        return commonConstants.OS_ANDROID
    } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        return commonConstants.OS_IOS
    } else {
        return commonConstants.OS_UNKOWN
    }
}
/**
 * to get the touch point
 * @returns touch point
 */
export const getTouchPoint = () => {
    // Check if the screen size matches a specific category
    const isMobile = window.matchMedia("(max-width: 767px)").matches;
    const isTablet = window.matchMedia("(min-width: 768px) and (max-width: 1024px)").matches;
    const isLaptop = window.matchMedia("(min-width: 1025px)").matches;

    // Determine the touch point based on the screen size
    if (isMobile) {
        return getOS()
    } else if (isTablet) {
        return commonConstants.TAB;
    } else if (isLaptop) {
        return commonConstants.DESKTOP;
    } else {
        return commonConstants.UNKNOWN;
    }
};

/**
 * it will identify type of event
 * @param {*} event all types of event
 * @returns if its valid event l.e enter and space 
 */
export const identifyEvent = (event) => {
  if (event.key === 'Enter' || event.key === ' ') return true
  return false
}

/**
 * get sorted array based on conditions
 * @param {*} data  array of data
 * @param {*} conditions list of conditions
 * @returns sorted array
 */
export const getSortedArray = (data,conditions,sortBasedOn) => {
    return data.sort((first, second) => {
        // Function to check if an element matches any condition
        const matchesCondition = (element, condition) => {
            return condition in element && element[condition] === conditions[condition];
        };

        // Prioritize elements that match the conditions
        for (let condition of Object.keys(conditions)) {
            if (matchesCondition(first, condition)) return -1;
            if (matchesCondition(second, condition)) return 1;
        }

        // If none of the conditions are matched, sort alphabetically based on the sortBasedOn property
        if (sortBasedOn === 'corporateBrandId') {
            return brandNameFromID[first[sortBasedOn]].localeCompare(brandNameFromID[second[sortBasedOn]]);
        }
        else if(sortBasedOn === 'countryCode'){
            return  countryNameFromID[first[sortBasedOn]].localeCompare(countryNameFromID[second[sortBasedOn]]);
        }
        else {
            return first[sortBasedOn].localeCompare(second[sortBasedOn]);
        }
    });
}

